<template>
    <div>
        <b-table small bordered hover show-empty :items="akseslists" :fields="fields" class="mb-3">
            <template #empty="scope"> Data tidak ditemukan </template>
            <template v-slot:table-busy>
                <div class="text-center my-2">
                    <b-spinner small class="align-middle" variant="secondary"></b-spinner>
                    <span class="font-weight-bold"> Harap tunggu...</span>
                </div>
            </template>
            <template v-slot:cell(user)="row">
                <div>
                    <span>{{ row.item.user.name }}</span>
                    <small class="d-block">{{ row.item.user.username }}</small>
                </div>
            </template>
            <template v-slot:cell(action)="row">
                <div class="d-flex">
                    <b-button @click="handleDelete(row.item)" class="mx-1" variant="danger" size="sm">
                        <i class="fa fa-trash"></i>
                    </b-button>
                    <b-button class="mx-1" variant="warning" size="sm">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: ['akseslists'],
    data() {
        return {
            fields: [
                { key: "kategori_proses_name", label: "Nama Kategori Proses" },
                { key: "user", label: "User" },
                { key: "action", label: "Aksi" },
            ],
        }
    },
    methods: {
        async handleDelete(value) {
            console.log(value)
        }
    }
}
</script>