<template>
    <div>
        <b-card>
            <template v-slot:header>
                <h5>Akses Inventaris</h5>
            </template>
            <ActionInventatis />
            <AksesInventatis :akseslists="akseslists" />
        </b-card>
    </div>
</template>


<script>
import AksesInventatis from "../../../components/masterinventaris/settingaccess/tableAksesInventaris.vue"
import ActionInventatis from "../../../components/masterinventaris/settingaccess/actionAksesInventaris.vue"
export default {
    components: {
        AksesInventatis,
        ActionInventatis
    },
    data() {
        return {

        }
    },
    computed: {
        akseslists() {
            return this.$store.state.inventaris.akseslists
        }
    },
    mounted() {
        this.$store.dispatch("getDivisi", "all")
        this.$store.dispatch("getAksesList")
        this.$store.dispatch("getListKatAkses")
    }
}
</script>