<template>
    <div>
        <div class="d-flex justify-content-end mb-3">
            <b-button @click="$bvModal.show('m-create')" variant="pgiBtn">
                <i class="fa fa-plus"></i>
                Tambah
            </b-button>
        </div>
        <b-modal id="m-create" title="Form Akses" centered no-close-on-backdrop no-close-on-esc hide-footer>
            <b-form-group label="Kategori" class="required">
                <multiselect placeholder="Pilih Kategori" class="multiselect" label="name" track-by="id" v-model="devisi"
                    :options="kategoriakseslist">
                </multiselect>
            </b-form-group>
            <b-form-group label="Divisi" class="required">
                <multiselect placeholder="Pilih Divisi" class="multiselect" label="name" track-by="id"
                    v-model="kategori_proses" :options="divisilist">
                </multiselect>
            </b-form-group>
            <b-form-group label="User" class="required">
                <multiselect :searchable="true" @search-change="handleChange" placeholder="Pilih User" class="multiselect"
                    label="name" track-by="id" v-model="user" :options="users">
                </multiselect>
            </b-form-group>
            <div class="d-flex justify-content-end mt-3">
                <b-button @click="handleSumbit" variant="pgiBtn">Submit</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from '../../../config/api'

export default {
    data() {
        return {
            user: null,
            devisi: null,
            kategori_proses: null,
            users: []
        }
    },
    methods: {
        async handleChange(value) {
            if (value.length > 1) {
                const data = await this.$store.dispatch("searchUser", {
                    search: value
                })
                this.users = data
            }
        },
        async handleSumbit() {
            const form = {
                user_id: this.user.id,
                devisi_id: this.devisi.id,
                kategori_proses: this.kategori_proses.id,
            }
            const response = await axiosClient({
                method: 'POST',
                url: 'transaksi/kategori-inventaris/create',
                data: form
            })
            if (response.status === 200) {
                this.$bvModal.hide('m-create')
            }
        }
    },
    computed: {
        kategoriakseslist() {
            return this.$store.state.inventaris.kategoriakseslist
        },
        divisilist() {
            return this.$store.state.global_data.divisilist
        },
    }
}
</script>